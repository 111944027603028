<template>
  <div class="containerCards">
    <div v-if="!isLoading" class="row row-cols-lg-4 g-2 g-lg-3">
      <div
        class="col col-sm-6 mt-4 col-12"
        v-for="item in items"
        :key="item._id"
      >
        <router-link style="text-decoration: none" :to="item.route">
          <div
            :style="`backgroundImage: url(${server_url}/${item.image});`"
            class="
              cardPhoto1
              justify-content-center
              d-flex
              align-items-center
              d-flex
              align-items-center align-self-middle
            "
          >
            <div class="card1 justify-content-center d-flex align-items-center">
              <span v-if="lang == 'ka'" v-html="item.titleKA">{{
                item.titleKA
              }}</span>
              <span v-else v-html="item.titleEN">{{ item.titleEN }}</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="row row-cols-lg-4 g-2 g-lg-3" v-else>
      <div
        class="col col-sm-6 mt-4 col-12"
        v-for="item in [1, 2, 3, 4]"
        :key="item"
      >
        <b-skeleton-img no-aspect height="18rem" type="input"></b-skeleton-img>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import env from "../../../env.json";
export default {
  data() {
    return {
      isLoading: false,
      items: [],
      lang: "ka",
      isLoading: false,
      server_url: env.server_url,
    };
  },
  mounted() {
    this.isLoading = true;
    axios
      .get(`${env.host}/get/informations/cards`)
      .then((result) => {
        console.log(result);
        this.items = [...result.data.item];
        this.isLoading = false;
      })

      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.card-body {
  padding: 0px;
}

.containerCards {
  padding: 0px;
  margin: 0px;
}
router-link p {
  text-decoration: none;
}
.card1 {
  background-color: rgba(0, 0, 0, 0.336);
  color: white !important;

  text-align: center;
  text-transform: uppercase;
}

.card1:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #7e5493 !important;
  transition: 0.4s !important;
}

.card,
.cardPhoto1,
.cardPhoto2,
.cardPhoto3,
.cardPhoto4,
.card1 {
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  padding: 0px;
  border-radius: 0px;
  align-items: center;
  border: none;
  color: #7e5493;
  font-size: 20px;

  height: 18rem;
  font-family: mtavruli !important;
}

.cardPhoto1 {
  text-align: center;
  align-items: center;
}

.withOut {
  height: 60px;
  padding: 0px;
  text-align: center;
}

@media all and (max-width: 991px) {
  .btnLONG {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
@media all and (max-width: 744px) {
  .btnLONG {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
@media all and (max-width: 575px) {
  .btnLONG {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
@media all and (max-width: 377px) {
  .btnLONG {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
</style>
