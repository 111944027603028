<template>
  <div class="newsContainer align-items-center">
    <div class="insideNews">
      <h1 style="text-transform: uppercase; font-family: mtavruliBOLD">
        {{ $t("navBar.news") }}
      </h1>
      <div class="row">
        <div
          class="mt-4 col-lg-6 col-sm-12"
          v-for="item in items"
          :key="item._id"
        >
          <div class="card bg-transparent">
            <div class="card-body">
              <h5 class="card-title">
                <span v-if="lang == 'ka'" v-html="item.dateKA">{{
                  item.dateKA
                }}</span>
                <span v-else v-html="item.dateEN">{{ item.dateEN }}</span>
              </h5>
              <p class="card-text">
                <span
                  v-if="lang == 'ka'"
                  v-html="removeHTML(subStringText(item.descriptionKA))"
                ></span>
                <span
                  v-else
                  v-html="removeHTML(subStringText(item.descriptionEN))"
                ></span>
              </p>
              <p class="seeMore mtavruli" style="">
                <router-link
                  class="btnArchive"
                  style="text-decoration: none"
                  :to="'/news' + '/' + item.slug"
                >
                  {{ $t("front.more") }}
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="newsArch">
        <router-link
          style="
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;
          "
          class="btnArchive"
          to="/newNews"
        >
          {{ $t("front.newsArchive") }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import env from "../../../env.json";

export default {
  data() {
    return {
      lang: "ka",
      items: [],
      currentPage: 1,
      perPage: 4,
      isLoading: true,
      video: [],
    };
  },
  methods: {
    removeHTML: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
    subStringText: function (string) {
      return (
        string.substring(0, 200).split("&nbsp;").join("").split("·&").join("") +
        "..."
      );
    },
  },
  mounted() {
    axios
      .get(
        `${env.host}/get/pagination/news/null/${this.currentPage}/${this.perPage}`
      )
      .then((result) => {
        this.items = [...result.data.item];
        this.paginationLength = result.data.total_page;
        this.isLoading = false;
      });

    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.card {
  border: none;
  min-height: 150px !important;
}
.card-body {
  padding: 0px;
}

.newsContainer {
  background-color: #f5f5f5;
}

.card-title {
  padding-bottom: 8px;
  border-bottom: 1.5px solid #c6bec9;
}

.insideNews {
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
  padding: 20px 0px;
  min-height: 200px;
}

.insideNews p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #212529;
}
.insideNews h1 {
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  font-family: mtavruliBOLD;
  color: #7e5493;
}
.seeMore {
  text-transform: uppercase;
}

.seeMore a {
  border-bottom: 1.5px solid #c6bec9;
  font-family: mtavruli;
  cursor: pointer !important;
}

a {
  text-decoration: none;

  color: #7e5493;
}
h5 {
  color: #7e5493;
  font-weight: bolder;
}

a:hover {
  color: #7e5493;
}

.newsArch {
  margin-right: 0px;
  float: right;
  margin-left: auto;
}
.btnArchive {
  border: none;
  color: #7e5493;
  font-family: mtavruli;
  border-bottom: 1.5px solid #c6bec9;
}
.button {
  border: 0px;
}

@media all and (max-width: 970) {
  .newsContainer {
    height: auto;
  }
}
</style>
