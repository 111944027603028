<template>
  <div class="home">
    <Navbar />
    <div class="startContainer">
      <div v-for="item in itemsCover" :key="item._id">
        <div>
          <div
            v-if="!isLoading"
            class="cardContainer"
            :style="{
              backgroundImage: `url('${server_url}/${item.image}')`,
            }"
          >
            <h2 style="color: white; font-weight: 600">
              <span
                class="mainWord"
                v-if="lang == 'ka'"
                v-html="item.textKA"
              ></span>
              <span class="mainWord" v-else v-html="item.textEN"></span>
            </h2>
          </div>
          <div v-else>
            <b-skeleton-img
              no-aspect
              height="18rem"
              type="input"
            ></b-skeleton-img>
          </div>
        </div>

        <div class="container mainCOntent mt-3">
          <MainCards />
        </div>

        <div class="news_video">
          <div class="align-items-center">
            <News />
          </div>
          <div
            class="videoSide"
            style="background-color: #7e5493"
            v-for="item in items"
            :key="item._id"
          >
            <iframe
              width="100%"
              height="100%"
              :src="item.url"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import MainCards from "./MainCards.vue";
import Navbar from "../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../components/Footer/newFooter/Footer.vue";
import News from "./News.vue";
import axios from "axios";
import env from "../../../env.json";

export default {
  name: "home",
  data() {
    return {
      items: [],
      isLoading: false,
      itemsCover: {},
      server_url: env.server_url,
      lang: "ka",
    };
  },
  components: {
    Navbar,
    MainCards,
    News,
    Footer,
  },
  mounted() {
    axios.get(`${env.host}/get/informations/video`).then((result) => {
      console.log(result);
      this.items = [result.data.item];
    });
    this.isLoading = true;
    console.log(this.isLoading);
    axios.get(`${env.host}/get/informations/hero`).then((result) => {
      console.log(result);
      this.itemsCover = [result.data.item];

      this.isLoading = false;

      // console.log(result.data.item.image, "veriko");
      console.log(this.server_url + "/" + result.data.item.image, "axali");
    });

    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
::v-deep h1 {
  font-family: dejavuBOLD;
  font-size: 95px;
  font-weight: 699;
  letter-spacing: 4px;
}
.startContainer {
  width: 100%;
  margin: auto;
  min-height: calc(100vh - 190px);
}

.cardContainer {
  background-color: #c4bec9;
  width: 100%;
  height: 26rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;

  text-align: center;
}
.cardContainer h2 {
  line-height: 1.6;
  letter-spacing: 1px;
}
.container {
  width: 100%;
  max-width: 1400px;
  padding: 0px;
}
.title {
  border-bottom: 4px solid #7e5493;
  margin-top: 20px;
  text-align: center;
  padding: 10px 40px 10px 40px;
}
.mainCOntent h1 {
  font-family: mtavruli;
}
.news_video {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin: auto;
  margin-top: 40px;
  max-width: 1500px;

  margin-bottom: 40px;
}
.mainWord {
  font-size: 95px;
  font-weight: 699;

  font-family: dejavuBOLD;
}
.secondWord {
  color: #c4bec9;
}
.quotes img {
  width: 55px;
}
.quotes1 img {
  padding-bottom: 50px;
  padding-right: 10px;
}
.quotes3 img {
  padding-top: 20px;
}

@media all and (max-width: 1500px) {
  .mainCOntent {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media all and (max-width: 970px) {
  .news_video {
    display: grid;

    grid-template-columns: auto !important;
  }
  .videoSide {
    height: 400px;
  }
}

@media all and (max-width: 654px) {
  .mainWord {
    font-size: 65px;
  }
  ::v-deep h1 {
    font-size: 65px !important;
  }
}

@media all and (max-width: 576px) {
  .mainWord {
    font-size: 60px;
  }
  ::v-deep h1 {
    font-size: 60px !important;
  }
  .cardContainer img {
    width: 40px;
  }
}
@media all and (max-width: 539px) {
  .mainWord {
    font-size: 60px;
  }
  ::v-deep h1 {
    font-size: 60px !important;
  }
  ::v-deep h2 {
    font-size: 28px !important;
  }
}
@media all and (max-width: 480px) {
  .mainWord {
    font-size: 45px !important;
  }
  ::v-deep h1 {
    font-size: 45px !important;
  }
  ::v-deep h2 {
    font-size: 25px !important;
  }
}
@media all and (max-width: 378px) {
  .mainWord {
    font-size: 40px;
    letter-spacing: 0;
  }
  ::v-deep h1 {
    font-size: 40px !important;
  }
  ::v-deep h2 {
    font-size: 22px !important;
  }
}
</style>
